import React, { useEffect } from 'react'
import { Fragment, useState } from 'react'
import { Listbox, Menu, Transition } from '@headlessui/react'
import {
    ArchiveBoxIcon as ArchiveBoxIconMini,
    ArrowUturnLeftIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    FolderArrowDownIcon,
    PencilIcon,
    TagIcon,
    CheckIcon,
    PhoneXMarkIcon,
    QuestionMarkCircleIcon,
    HandRaisedIcon,
    EnvelopeIcon,
    ChatBubbleBottomCenterIcon,
} from '@heroicons/react/20/solid'
import MessagingSmsSender from './sender/MessagingSmsSender'
import MessagingEmailSender from './sender/MessagingEmailSender'
import MessagingNewNoteModal from './notes/MessagingNewNoteModal'
import { GlobalStateContext } from '../state/globalState';

import { loadingScreen } from '../common/fetchdata';
import { postJson, blankResult, reqOk } from "../common/fetchdata";
import AvatarXs from '../common/card/AvatarXs'
import LeadAssignmentMenu from './actions/LeadAssignmentMenu'
import LeadStatusMenu from './actions/LeadStatusMenu'

const moods = [
    { name: 'Aberto', value: 'OPEN', icon: PhoneXMarkIcon, iconColor: 'text-white', bgColor: 'bg-red-500' },
    { name: 'Fechado', value: 'CLOSED', icon: CheckIcon, iconColor: 'text-white', bgColor: 'bg-green-400' },
    { name: 'Pendente', value: 'PENDING', icon: QuestionMarkCircleIcon, iconColor: 'text-white', bgColor: 'bg-yellow-400' },
]



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function MessagingActions({ selectedTopic, handleRefresh }) {

    const [smsSenderOpen, setSmsSenderOpen] = useState(false)
    const [emailSenderOpen, setEmailSenderOpen] = useState(false)
    const [emailDestination, setEmailDestination] = useState({ name: '', email: '' })

    const [newNoteModal, setNewNoteModal] = useState(false)
    const [selected, setSelected] = useState({ value: null })

    const [pres, setPres] = useState(blankResult);
    const globalcontext = React.useContext(GlobalStateContext);
    const canSendMsg = globalcontext.contextFcn.userData.canReadResource('crm:sendmessaging')
    const canSendEmail = globalcontext.contextFcn.userData.canReadResource('crm:sendmessagingemail')

    function anyActiveTopic() {
        return !(Object.keys(selectedTopic).length === 0);
    }

    useEffect(() => {
        loadingScreen(pres, globalcontext)
    }, [pres]);



    useEffect(() => {
        if (selectedTopic.current_status) {
            setSelected(moods.filter(s => s.value == selectedTopic.current_status)[0])
        }
        else {
            setSelected({ value: null })
        }
    }, [selectedTopic])

    useEffect(() => {
        if (reqOk(pres)) {
            handleRefresh()
        }
    }, [pres])


    const replyDefault = [

        {
            name: 'SMS', hasAccess: canSendMsg, description: null, icon: ChatBubbleBottomCenterIcon, action: () => {
                if (canSendMsg) setSmsSenderOpen(true);

            }
        },
    ]

    function sendEmail(emailObj) {

        setEmailSenderOpen(true)
        setEmailDestination(emailObj)
    }

    function replyOptions() {

        let emailContacts = selectedTopic.booking_request.map(b => ({ email: b.email, name: b.first_name + ' ' + b.last_name }))
        emailContacts = emailContacts.filter((v, i, a) => a.findIndex(v2 => (v2.email === v.email)) === i)

        let _replyOpts = replyDefault

        emailContacts.forEach(e => {
            _replyOpts = [..._replyOpts, {
                name: 'Email', hasAccess: canSendEmail, description: e.email, emailName: e.name, icon: EnvelopeIcon, action: () => {
                    if (canSendEmail) sendEmail(e);

                }
            }]
        });

        return _replyOpts
    }




    return (
        <>
            {anyActiveTopic() && <>
                <MessagingSmsSender open={smsSenderOpen} setOpen={setSmsSenderOpen} selectedTopic={selectedTopic} handleRefreshTopic={handleRefresh} />
                <MessagingEmailSender open={emailSenderOpen} setOpen={setEmailSenderOpen} selectedTopic={selectedTopic} handleRefreshTopic={handleRefresh} destination={emailDestination} />


                <MessagingNewNoteModal open={newNoteModal} setOpen={setNewNoteModal} selectedTopic={selectedTopic} handleRefresh={handleRefresh} />

                {/* Top section */}
                <div className="flex-shrink-0 border-b border-gray-200 bg-white">
                    {/* Toolbar*/}
                    <div className="flex h-16 flex-col justify-center">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="flex justify-between py-3">
                                {/* Left buttons */}
                                <div>
                                    <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                                        <span className="inline-flex sm:shadow-sm">

                                            {/* <button
                                                type="button"
                                                onClick={() => {
                                                    if (canSendMsg) setSmsSenderOpen(true);
                                                }}
                                                className={`relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10`}
                                            >
                                                <ArrowUturnLeftIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                Responder
                                            </button> */}

                                            <Listbox as="div" value={selected} onChange={setSelected} className="sm:ml-3">
                                                {({ open }) => (
                                                    <>
                                                        <Listbox.Label className="sr-only">Change published status</Listbox.Label>
                                                        <div className="relative">
                                                            <Listbox.Button className="hover:bg-gray-50">
                                                                <div
                                                                    type="button"
                                                                    className={`relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10  focus:z-10`}
                                                                >
                                                                    <ArrowUturnLeftIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                    Responder
                                                                </div>
                                                            </Listbox.Button>

                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="truncate absolute mt-2 w-52 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                    {replyOptions().map((reply, i) => (
                                                                        <Listbox.Option
                                                                            onClick={() => reply.action()}
                                                                            key={i}
                                                                            className={({ active }) =>
                                                                                classNames(
                                                                                    active ? 'bg-gray-200 text-gray-900' : 'text-gray-900',
                                                                                    'group flex w-full items-center rounded-md p-3 text-sm',
                                                                                    reply.hasAccess ? "cursor-pointer" : "cursor-not-allowed"
                                                                                )
                                                                            }
                                                                            value={reply}
                                                                        >
                                                                            <div className="truncate">
                                                                                <div className="flex items-center">
                                                                                    <div
                                                                                        className={classNames(

                                                                                            'bg-sky-600',
                                                                                            'flex h-6 w-6 items-center justify-center rounded-full'
                                                                                        )}
                                                                                    >
                                                                                        <reply.icon
                                                                                            className={classNames("text-white", 'h-4 w-4 flex-shrink-0')}
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </div>
                                                                                    <span className="ml-3 block truncate font-normal">{reply.name}</span>
                                                                                </div>
                                                                                {reply.description != null &&
                                                                                    <div className="text-xs p-2">
                                                                                        <p className="pb-1 font-medium">{reply.emailName}</p>
                                                                                        <p>{reply.description}</p>
                                                                                    </div>}
                                                                            </div>
                                                                        </Listbox.Option>
                                                                    ))}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox>
                                            <button
                                                type="button"
                                                onClick={() => setNewNoteModal(true)}
                                                className={`relative -ml-px hidden items-center gap-x-1.5 bg-white px-3 py-2 ` +
                                                    ` text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 ` +
                                                    `hover:bg-gray-50 focus:z-10 sm:inline-flex`
                                                }
                                            >
                                                <PencilIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                Nota
                                            </button>

                                            <div className="relative">
                                                <LeadStatusMenu
                                                    actionBtn={
                                                        <>
                                                            {selected.value === null ? (
                                                                <TagIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            ) : (
                                                                <span>
                                                                    <span
                                                                        className={classNames(
                                                                            selected.bgColor,
                                                                            'flex h-5 w-5 items-center justify-center rounded-full'
                                                                        )}
                                                                    >
                                                                        <selected.icon
                                                                            className="h-3 w-3 flex-shrink-0 text-white"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                    <span className="sr-only">{selected.name}</span>
                                                                </span>
                                                            )}
                                                            Estado
                                                        </>}
                                                    selectedTopic={selectedTopic}
                                                    handleRefresh={handleRefresh} />
                                            </div>



                                            {/*  <Listbox>
                                                {({ open }) => (
                                                    <>
                                                        <Listbox.Label className="sr-only">Atribuir</Listbox.Label>
                                                        <div className="relative">
                                                            <Listbox.Button className="relative -ml-px hidden items-center gap-x-1.5 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex">
                                                                <TagIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                Atribuir
                                                            </Listbox.Button>

                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                                                                    <Listbox.Option
                                                                        onClick={() => doAssignmentChange(globalcontext.userData.items.user.id, globalcontext.userData.items.user.fullname)}
                                                                        className={({ active }) =>
                                                                            classNames(
                                                                                active ? 'bg-gray-200 text-gray-900' : 'text-gray-900',
                                                                                'group flex w-full items-center rounded-md p-3 text-sm cursor-pointer'
                                                                            )
                                                                        }
                                                                    //value={mood}
                                                                    >
                                                                        <div className="flex items-center">
                                                                            <div
                                                                                className={classNames(
                                                                                    'bg-sky-600 flex h-6 w-6 items-center justify-center rounded-full'
                                                                                )}
                                                                            >
                                                                                <HandRaisedIcon
                                                                                    className={'text-white  h-4 w-4 flex-shrink-0'}
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </div>
                                                                            <span className="ml-3 block truncate font-normal">Atribuir a mim</span>
                                                                        </div>
                                                                    </Listbox.Option>


                                                                    <div className={'text-gray-90 group flex flex-col w-full rounded-md p-3 text-sm '}>
                                                                        <div className="pb-3 text-xs text-gray-700 font-medium">Atribuir a:</div>
                                                                        <div className="flex items-center ">

                                                                            <SelectProvider sel={assigneeSelected} setSel={setAssigneeSelected} showTitle={false} filterByRole={['crm:selfassign']} />
                                                                        </div>

                                                                        <div className=" text-xs text-gray-700 font-medium flex flex-row mt-3">
                                                                            <div className="w-full"></div>
                                                                            <div className="m-auto">
                                                                                <button
                                                                                    onClick={() => doAssignmentChange(assigneeSelected.id, assigneeSelected.fullname)}
                                                                                    type="button"
                                                                                    className="rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                                                >
                                                                                    Guardar
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox> */}

                                            <div className="relative">
                                                <LeadAssignmentMenu
                                                    actionBtn={
                                                        <>
                                                            <div className="-ml-px hidden items-center gap-x-1.5 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex">
                                                                <TagIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                Atribuir
                                                            </div>
                                                        </>}
                                                    selectedTopic={selectedTopic}
                                                    handleRefresh={handleRefresh} />
                                            </div>

                                        </span>


                                        <Menu as="div" className="relative -ml-px  sm:shadow-sm hidden">
                                            <div>
                                                <Menu.Button className="relative inline-flex items-center gap-x-1.5 rounded-r-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 sm:rounded-md sm:px-3">
                                                    <span className="sr-only sm:hidden">More</span>
                                                    <span className="hidden sm:inline">More</span>
                                                    <ChevronDownIcon className="h-5 w-5 text-gray-400 sm:-mr-1" aria-hidden="true" />
                                                </Menu.Button>
                                            </div>

                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1">
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href="#"
                                                                    onClick={() => setNewNoteModal(true)}
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm sm:hidden'
                                                                    )}
                                                                >
                                                                    Nota
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href="#"
                                                                    className={classNames(
                                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                        'block px-4 py-2 text-sm sm:hidden'
                                                                    )}
                                                                >
                                                                    Estado
                                                                </a>
                                                            )}
                                                        </Menu.Item>

                                                    </div>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    </div>
                                </div>



                                {/* Right buttons */}
                                {/*  <nav aria-label="Pagination">
                                    <span className="isolate inline-flex rounded-md shadow-sm">
                                        <a
                                            href="#"
                                            className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10"
                                        >
                                            <span className="sr-only">Next</span>
                                            <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                                        </a>
                                        <a
                                            href="#"
                                            className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10"
                                        >
                                            <span className="sr-only">Previous</span>
                                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                                        </a>
                                    </span>
                                </nav> */}

                                <nav aria-label="Pagination">
                                    {selectedTopic.assigned_user != null ?
                                        <div className="isolate inline-flex rounded-md shadow-sm">

                                            <div className="p-3 text-xs font-medium">Assignado a</div>

                                            <div
                                                className="text-xs inline-flex items-center font-bold leading-sm  bg-blue-200 text-blue-700 rounded-full"
                                            >
                                                <AvatarXs person={selectedTopic.assigned_user} size={10} fontWeight={'medium'} fontSize={'xs'} />
                                                <div className="p-3">{selectedTopic.assigned_user.fullname}</div>
                                            </div>

                                        </div> :
                                        <div className="isolate inline-flex rounded-md shadow-sm">


                                            <div
                                                className="text-xs inline-flex items-center font-bold leading-sm   bg-orange-200 text-orange-700 rounded-full"
                                            >
                                                <div className="p-3">Não assignado</div>
                                            </div>

                                        </div>
                                    }

                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </>}

        </>
    )
}
